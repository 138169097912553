import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useLang } from "../lib/use-lang";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PropsContent {
  title: React.ReactNode;
  description: string;
  image: string;
  styleImage?: string;
  borderImage?: boolean;
}

const DetailFeature = ({
  title,
  description,
  image,
  styleImage = "",
  borderImage = false,
}: PropsContent) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect after the component mounts
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`min-h-[500px] relative transition-opacity duration-500 ease-in-out ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className="flex  flex-col lg:flex-row lg:m-auto lg:w-full
      justify-center   z-20 bg-white rounded-2xl items-center p-8 max-w-7xl "
      >
        <div className="flex-1">
          <p
            className="text-2xl font-bold mb-2 lg:mb-6 lg:mt-16"
            dangerouslySetInnerHTML={{ __html: title as string }}
          ></p>
          <p
            className="lg:max-w-[500px] text-xl font-medium"
            dangerouslySetInnerHTML={{ __html: description as string }}
          ></p>
        </div>
        <div className="md:w-[650px] mt-4 ">
          {!borderImage ? (
            <img
              src={image}
              className={
                "z-40 rounded-2xl max-w-full md:w-auto md:absolute right-0 top-0 max-h-[500px] " +
                styleImage
              }
              alt={title as string}
              loading="eager"
            />
          ) : (
            <div
              className="relative mx-auto max-w-7xl rounded-[32px] border border-neutral-200/50 
         p-2 backdrop-blur-lg dark:border-neutral-700 dark:bg-neutral-800/50 md:p-4 bg-neutral-100
        "
            >
              <div className="rounded-3xl border border-neutral-200 bg-white p-2 dark:border-neutral-700 dark:bg-black">
                <img
                  src={image}
                  width="700"
                  className={"z-40 rounded-2xl"}
                  alt="Hero Illustration"
                  loading="eager"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface Props {
  onChangeTitle?: (title: string) => void;
}
export default function Tabs({ onChangeTitle }: Props) {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  const [lang] = useLang();

  useEffect(() => {
    setTab(0);
  }, [lang]);

  const features = [
    {
      name: t("features.home.name"),
      image: "/img/home-icon.png",
      content: (
        <DetailFeature
          title={t("features.home.title")}
          description={t("features.home.description")}
          image="/img/home-feature.png"
        ></DetailFeature>
      ),
    },
    {
      name: t("features.dailyTask.name"),
      image: "/img/organiza.svg",
      content: (
        <DetailFeature
          title={t("features.dailyTask.title")}
          description={t("features.dailyTask.description")}
          image="/img/workflow.png"
        ></DetailFeature>
      ),
    },
    {
      name: t("features.assistantAI.name"),
      image: "/img/ai.png",
      content: (
        <DetailFeature
          title={t("features.assistantAI.title")}
          description={t("features.assistantAI.description")}
          image="/img/assitant-ai.png"
        ></DetailFeature>
      ),
    },

    {
      name: t("features.engagement.name"),
      image: "/img/desafia.svg",
      content: (
        <DetailFeature
          title={t("features.engagement.title")}
          description={t("features.engagement.description")}
          image="/img/gamification.png"
        ></DetailFeature>
      ),
    },

    {
      name: t("features.rewards.name"),
      image: "/img/reward.svg",
      content: (
        <DetailFeature
          title={t("features.rewards.title")}
          description={t("features.rewards.description")}
          image="/img/reward.png"
        ></DetailFeature>
      ),
    },

    {
      name: t("features.peopleAnalytics.name"),
      image: "/img/insight.png",
      content: (
        <DetailFeature
          title={t("features.peopleAnalytics.title")}
          description={t("features.peopleAnalytics.description")}
          image="/img/insight-feature.png"
        ></DetailFeature>
      ),
    },

    {
      name: t("features.chat.name"),
      image: "/img/chat.png",
      content: (
        <DetailFeature
          title={t("features.chat.title")}
          description={t("features.chat.description")}
          image="/img/chat-feature.png"
        ></DetailFeature>
      ),
    },
  ];
  return (
    <div className="flex w-full lg:justify-center items-center relative">
      <div className="w-full z-40 ">
        <TabGroup selectedIndex={tab} onChange={(value) => setTab(value)}>
          <TabList className="flex gap-4 lg:gap-14 lg:pt-10 overflow-x-auto pl-8 lg:pl-0 lg:justify-center">
            {features.map(({ name, image }) => (
              <Tab
                key={name}
                className="py-1 lg:py-3 flex flex-col whitespace-nowrap items-center px-3 text-sm/6 font-semibold text-gray-500 focus:outline-none border-b-4 border-white data-[selected]:border-secondary data-[selected]:text-black data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                <img
                  src={image}
                  className={
                    "lg:mb-2 w-[35px] h-[35px] lg:w-[45px] lg:h-[45px]"
                  }
                  alt={name}
                  loading="eager"
                />
                {name}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="mt-3">
            {features.map(({ name, content }) => (
              <TabPanel key={name} className="rounded-xl bg-white/5 py-3">
                {content}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
}
